import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/KudiltechLogoWhite.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const HeaderContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between; /* Align items properly on both desktop and mobile */
  align-items: center;
  padding: 0.5rem 2rem;
  position: sticky;
  top: 0;
  z-index: 10;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1rem; /* Slightly larger padding for smaller screens */
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center; /* Keep items aligned vertically */
  gap: 1rem;
  margin: 0;

  @media (max-width: 768px) {
    flex-direction: row; /* Retain horizontal alignment on mobile */
    justify-content: flex-start; /* Align items to the left */
  }
`;

const Logo = styled.img`
  height: 60px;
  width: auto;
  cursor: pointer;
  margin: 0;

  @media (max-width: 768px) {
    height: 50px; /* Adjust for smaller screens */
  }
`;


const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Always left-align text */
  //max-width: 300px;
  //word-wrap: break-word;

  @media (max-width: 768px) {
    align-items: flex-start; /* Prevent centering on mobile */
    max-width: 100%;
  }
`;


const CompanyName = styled(Link)`
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  margin: 0;
  line-height: 1.2; /* Better spacing */

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const Tagline = styled.p`
  font-size: 1rem;
  color: white;
  margin: 0;
  margin-top: 0.2rem;
  font-style: italic;
  max-width: 100%;
  line-height: 1.5;

  @media (max-width: 768px) {
    display: inline-block; /* Allow wrapping */
    max-width: 30ch; /* Limit characters per line (approx. 4 words) */
    word-wrap: break-word; /* Ensure words wrap properly */
    white-space: normal; /* Allow multi-line wrapping */
  }
`;



// Navigation Menu (Desktop)
const Nav = styled.nav`
  display: flex;
  gap: 2rem;

  a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }

  @media (max-width: 768px) {
    display: none;  /* Hide on mobile */
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }
`;

// Mobile Menu
const MobileMenu = styled.div`
  position: absolute;
  top: 80px;  /* Position the mobile menu below the header */
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.primary};
  padding: 1rem;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;

  a {
    text-decoration: none;
    font-size: 1.2rem;
    color: white;
    padding: 0.5rem;
    border: 1px solid white;
    border-radius: 5px;
    text-align: center;
    transition: background 0.3s, color 0.3s;

    &:hover {
      background: white;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;



const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <HeaderContainer>
      {/* Left Section: Logo, Company Name, and Tagline */}
      <LeftContainer>
        <Link to="/">
          <Logo src={logo} alt="Company Logo" />
        </Link>
        <TextWrapper>  
          <CompanyName to="/">KudilTech</CompanyName>
          <Tagline>Automating customer service</Tagline>
          </TextWrapper>
      </LeftContainer>

      {/* Desktop Navigation */}
      <Nav>
        <Link to="/">Home</Link>
        <Link to="/contact">Contact</Link>
      </Nav>

      {/* Hamburger Menu Icon */}
      <MenuIcon onClick={() => setMenuOpen((prev) => !prev)}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </MenuIcon>

      {/* Mobile Menu */}
      <MobileMenu isOpen={menuOpen}>
        <Link to="/" onClick={() => setMenuOpen(false)}>
          Home
        </Link>
        <Link to="/contact" onClick={() => setMenuOpen(false)}>
          Contact
        </Link>
      </MobileMenu>
    </HeaderContainer>
  );
};

export default Header;
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const topBannerVideo = "https://storage.googleapis.com/public-kudiltech-dot-com/public/MynaBannerVideo.mp4";
const video2 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video2.mp4';
const video3 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video3.mp4';
const video2_720 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video2-720.mp4';
const video3_720 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video3-720.mp4';
const mynaVideo1 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/MynaVideo1.mp4';
const mynaVideo2 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/MynaVideo2.mp4';


const Features = () => {
  return (
    <FeaturesSection>
      <TopBannerWrapper>
        <TopBannerVideo controls
            autoPlay
            muted
            loop
            playsInline
            preload="metadata"
        >
          <source src={topBannerVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </TopBannerVideo>
      </TopBannerWrapper>
      <VideoPlayer />
      <ContentSection>
        <div className="tile">
          <h3>What is Myna?</h3>
          <p>Myna, a product of KudilTech, leverages advanced AI to automate customer service interactions. By integrating with your knowledge base, CRM, and ticketing systems, Myna learns and builds a comprehensive understanding of your business. This enables it to accurately process customer requests via phone and chat, providing timely responses and executing transactions as programmed. Experience 24/7 availability, multi-lingual capabilities, and consistent, professional interactions.</p>
        </div>
        <div className="tile">
          <h3>How Myna Helps Your Business</h3>
          <p><b>Transform Customer Service & Drive Efficiency with Myna</b></p>
          <p>Myna isn't just an AI chatbot; it's a strategic asset that empowers your business to deliver exceptional customer experiences and optimize operations. Here's how Myna can make a tangible difference:</p>
          <ul>
            <li><strong>24/7 Availability & Instant Support:</strong> Eliminate wait times and provide immediate assistance, ensuring customer satisfaction around the clock. Myna operates tirelessly, handling inquiries and resolving issues instantly, freeing up your human agents for more complex tasks.</li>
            <li><strong>Reduced Operational Costs:</strong> Automate routine customer interactions, significantly lowering your support costs. Myna handles high volumes of inquiries efficiently, minimizing the need for extensive human resources.</li>
            <li><strong>Enhanced Customer Satisfaction:</strong> Provide consistent, accurate, and personalized support across multiple channels. Myna's ability to learn and adapt ensures every customer interaction is positive and efficient.</li>
            <li><strong>Seamless Integration & Data-Driven Insights:</strong> Integrate Myna with your existing CRM, knowledge base, and ticketing systems to create a unified customer experience. Gain valuable insights from customer interactions to improve your products and services.</li>
            <li><strong>Scalable & Multi-lingual Support:</strong> Expand your reach to a global audience with Myna's multi-lingual capabilities. Easily scale your support operations to meet growing customer demands without adding significant overhead.</li>
            <li><strong>Increased Agent Productivity:</strong> By handling repetitive tasks and providing instant information, Myna allows your human agents to focus on complex issues and deliver higher-value support.</li>
            <li><strong>Consistent Brand Messaging:</strong> Ensure consistent and accurate information is delivered to your customers every time, reinforcing your brand's image and building trust.</li>
          </ul>
          <p><b>Invest in Myna and experience the future of customer service automation. Drive efficiency, enhance customer satisfaction, and gain a competitive edge.</b></p>
        </div>
        <div className="tile">
          <h3>Who Needs Myna?</h3>
          <ul>
          <li><strong>Customer Service Managers:</strong> To optimize support operations and reduce costs.</li>
              <ul>
                <li><strong>Support Teams:</strong> To free up agents for complex issues and improve efficiency.</li>
                <li><strong>Sales Teams:</strong> To provide instant product information and support pre-sales inquiries</li>
                <li><strong>Operations Teams:</strong> To streamline processes and improve overall efficiency.</li>
                <li><strong>Marketing Teams:</strong> to improve customer interactions, and brand consistency.</li>
                <li><strong>Business Owners/Executives:</strong> To drive customer satisfaction, reduce operational costs, and gain a competitive edge.</li>
              </ul>
          </ul>
        </div>
      </ContentSection>
    </FeaturesSection>
  );
};

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) video.pause();
    else video.play();
    setIsPlaying(!isPlaying);
  };

  const handleProgressClick = (e) => {
    const video = videoRef.current;
    const progressBar = e.target.getBoundingClientRect();
    const clickPosition = e.clientX - progressBar.left;
    const newTime = (clickPosition / progressBar.width) * video.duration;
    video.currentTime = newTime;
    setProgress((newTime / video.duration) * 100);
  };

  const updateProgress = () => {
    const video = videoRef.current;
    setProgress((video.currentTime / video.duration) * 100);
    setCurrentTime(formatTime(video.currentTime));
    setDuration(formatTime(video.duration));
  };

  return (
    <FirstFeatureCard>
      <video
        ref={videoRef}
        preload="metadata"
        onTimeUpdate={updateProgress}
        onLoadedMetadata={(e) => setDuration(formatTime(e.target.duration))}
      >
        <source src={mynaVideo1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="controls">
        <button className="control-button" onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </button>
        <div className="progress-bar" onClick={handleProgressClick}>
          <div style={{ width: `${progress}%` }}></div>
        </div>
        <span className="time">{currentTime} / {duration}</span>
      </div>
    </FirstFeatureCard>
  );
};

const FeaturesSection = styled.section`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const TopBannerWrapper = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 95%;
    padding: 0.5rem;
  }
`;

const TopBannerVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  display: block;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;


const FirstFeatureCard = styled.div`
  width: 90%;
  max-width: 1400px;
  height: 400px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  .controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 600px;
    padding: 4px 7px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    gap: 10px;
  }

  .control-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: white;
    border: none;
    border-radius: 10%;
    cursor: pointer;
    transition: transform 0.2s ease;

    svg {
      color: #1565c0;
      font-size: 20px;
    }
  }

  .progress-bar {
    flex: 1;
    height: 4px;
    margin: 0 10px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    & > div {
      height: 100%;
      background: linear-gradient(90deg, #42a5f5, #1e88e5);
      transition: width 0.1s linear;
    }
  }

  .time {
    font-size: 12px;
    color: white;
    font-weight: bold;
  }
`;

const ContentSection = styled.section`
  padding: 3rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;

  .tile {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-5px);
    }

    h3 {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.colors.primary};
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      color: ${({ theme }) => theme.colors.text};
      margin-bottom: 1rem;
    }

    ul {
      margin: 0;
      padding-left: 1.5rem;
      list-style-type: disc;

      li {
        font-size: 0.95rem;
        color: ${({ theme }) => theme.colors.textLight};
        margin-bottom: 0.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    grid-template-columns: 1fr;

    .tile {
      padding: 1.2rem;
      h3 {
        font-size: 1.3rem;
      }
      p,
      li {
        font-size: 0.9rem;
      }
    }
  }
`;

export default Features;
